import './Welcome.css';
import { useState } from 'react';
import config from "../../config/config"
import Cards from './Cards';
import RSVPSurvey from '../rsvp-survey/RSVPSurvey';
import { Redirect } from 'react-router-dom';


function RSVP() {
    let params = new URL(document.location.toString()).searchParams;
    let userid = params.get("userid");
    let experience = params.get("experience")
    const [mode, setMode] = useState(undefined);

  if(userid == undefined){
    return <Redirect to='/' />
  }
  return mode == undefined ? <div id="welcome">
  <svg viewBox="0 0 40 40" display="none" width="0" height="0">
      <symbol id="icon-0fab58c8cba7ae847fa5ba32ea5a4bef" viewBox="0 0 40 40">
          <path
              d="M20.9,37L33,25.3c3-3,4.5-5.9,4.5-8.8c0-2.9-0.8-5.1-2.5-6.7c-1.6-1.6-3.9-2.4-6.8-2.4c-0.8,0-1.6,0.1-2.5,0.4 S24.1,8.5,23.3,9c-0.7,0.5-1.3,0.9-1.9,1.3c-0.5,0.4-1,0.9-1.5,1.3c-0.5-0.5-1-0.9-1.5-1.3C18,9.9,17.4,9.5,16.7,9 c-0.7-0.5-1.5-0.9-2.3-1.1s-1.7-0.4-2.5-0.4C9,7.5,6.7,8.3,5,9.9s-2.5,3.8-2.5,6.7c0,0.9,0.2,1.8,0.5,2.7c0.3,0.9,0.7,1.7,1,2.4 c0.4,0.6,0.8,1.3,1.3,1.9c0.5,0.6,0.9,1,1.1,1.3C6.7,25,6.9,25.2,7,25.3L19.1,37c0.2,0.2,0.5,0.4,0.9,0.4S20.6,37.3,20.9,37z">
          </path>
      </symbol>
  </svg>
  <div id="wrapper">
      <div id="main">
          <div class="inner">
              
              <section id="welcome-section" class="active">
                  <div id="image03" class="image" data-position="center"><span class="frame"><img
                              src="img/logo.svg" alt=""/></span></div>
                  <div id="gallery01" class="gallery" style={{opacity: 1}}>
                      <div class="inner">
                          <ul>
                              <li><span class="frame"><img src="img/1.jpg"
                                          alt="Untitled"/></span></li>
                              <li><span class="frame"><img src="img/2.jpg"
                                          alt="Untitled"/></span></li>
                              <li><span class="frame"><img src="img/3.jpg"
                                          alt="Untitled"/></span></li>
                          </ul>
                      </div>
                  </div>
                  <footer id="footer">
                  <p id="text04"><u><strong>RSVP for July 24th @7pm</strong></u></p>

                  <ul id="buttons02" class="buttons">
                  <li><a href="#" onClick={() => setMode("yes")}class="button n01"><svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 40 40" display="none" width="0" height="0"><path d="M20.9,37L33,25.3c3-3,4.5-5.9,4.5-8.8c0-2.9-0.8-5.1-2.5-6.7c-1.6-1.6-3.9-2.4-6.8-2.4c-0.8,0-1.6,0.1-2.5,0.4 S24.1,8.5,23.3,9c-0.7,0.5-1.3,0.9-1.9,1.3c-0.5,0.4-1,0.9-1.5,1.3c-0.5-0.5-1-0.9-1.5-1.3C18,9.9,17.4,9.5,16.7,9 c-0.7-0.5-1.5-0.9-2.3-1.1s-1.7-0.4-2.5-0.4C9,7.5,6.7,8.3,5,9.9s-2.5,3.8-2.5,6.7c0,0.9,0.2,1.8,0.5,2.7c0.3,0.9,0.7,1.7,1,2.4 c0.4,0.6,0.8,1.3,1.3,1.9c0.5,0.6,0.9,1,1.1,1.3C6.7,25,6.9,25.2,7,25.3L19.1,37c0.2,0.2,0.5,0.4,0.9,0.4S20.6,37.3,20.9,37z"></path></svg><span class="label">Yes</span></a></li>

                  <li><a href="#" onClick={() => setMode("no")}class="button n01"><svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 40 40" display="none" width="0" height="0"><path d="M20.9,37L33,25.3c3-3,4.5-5.9,4.5-8.8c0-2.9-0.8-5.1-2.5-6.7c-1.6-1.6-3.9-2.4-6.8-2.4c-0.8,0-1.6,0.1-2.5,0.4 S24.1,8.5,23.3,9c-0.7,0.5-1.3,0.9-1.9,1.3c-0.5,0.4-1,0.9-1.5,1.3c-0.5-0.5-1-0.9-1.5-1.3C18,9.9,17.4,9.5,16.7,9 c-0.7-0.5-1.5-0.9-2.3-1.1s-1.7-0.4-2.5-0.4C9,7.5,6.7,8.3,5,9.9s-2.5,3.8-2.5,6.7c0,0.9,0.2,1.8,0.5,2.7c0.3,0.9,0.7,1.7,1,2.4 c0.4,0.6,0.8,1.3,1.3,1.9c0.5,0.6,0.9,1,1.1,1.3C6.7,25,6.9,25.2,7,25.3L19.1,37c0.2,0.2,0.5,0.4,0.9,0.4S20.6,37.3,20.9,37z"></path></svg><span class="label">No</span></a></li>
                  </ul>
                 
                
              </footer>
                  <p id="text04"><u><strong>RSVP Guidelines </strong></u></p>
                  <p id="text02"><span class="p">
                      <strong>When:</strong> July 14th @ 9pm <strong>Where:</strong> Surprise location<br/> 
                      <strong>RSVP Deadline:</strong> Tuesday, July 23th by 9pm. <strong>Ticket Price: </strong>$9 per person.<br/>
                      <strong>Limited Space:</strong> We have limited space available. Once tickets are sold out, we cannot accommodate additional attendees.<br/>
                      <strong>Final Confirmation:</strong> Confirming your attendance is final. No-shows without prior notice is not tolerated.<br/>
                      <strong>Guest Policy: </strong>Each attendee can bring one additional guest (+1). Guests are free!<br/>
                      <strong>Event Details:</strong> This experience is hosted at a local restaurant with your closest matches. Your guest will always be matched to your table. There is an entrance fee for the optional post-dinner retro arcade.<br/>
                      <strong>Matching: </strong>We match based on compatibility. If not selected, it means your preferred table-mates weren't available. We look forward to hosting you at a future event.<br/>
                      <strong>Refunds: </strong>You'll be immediately refunded 100% if you're not matched!<br/>
                      </span>
                      </p>
              </section>
              
              
              
             
    
          </div>
      </div>
  </div>
</div> : <RSVPSurvey mode={mode} userid={userid} />

    
}

export default RSVP;
