import { React} from 'react';

import JotFormReact from "../../JotFormReact"

import config from "../../config/config";
import {sendEvent} from "../../helpers"
// import { Navigation } from '../questions/components/navigation';

// Main App component
const RSVPSurvey = (props) => {
  console.log()
  let params = new URL(document.location.toString()).searchParams;
  let userid = params.get("userid");
  console.log(userid);
    const handleSubmit = () => {
      sendEvent('survey_completed', {
        category: 'Survey',
        label: 'RSVP Survey'
    })
    };
  var urlToUse = props.mode == "yes" ? config.YES_RSVP_URL+"?userid="+userid : config.NO_RSVP_URL+"?userid="+userid
  return (<div>
    <JotFormReact
      formURL={urlToUse}
      onSubmit={handleSubmit}
      autoResize={true} // Enable auto-resizing
    />
  </div>
  )

};

export default RSVPSurvey;